import { useEffect, useState } from "react";
import Button from "../../components/Button";
import { Col, Divider, Input, Row, Popconfirm, Space, Image, notification, Table, Tooltip } from "antd";
import "./styles.scss";
import Select from "../../components/Select/Select";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { useAuth0 } from "@auth0/auth0-react";
import { getAllSchedulers, setScheduler, deleteScheduler, updateScheduler } from "../../api/services/Unit";
import { NotificationType } from "../../utils/notifictions";
import "react-alice-carousel/lib/alice-carousel.css";
import PublisherSelect from "../DomainSelection/PublisherSelect";
import PureCard from "../../components/PureCard/PureCard";
import { sendGtmEvent } from "../../gtm";
import instagramIcon from "../../assets/images/instagram.svg";
import tiktokIcon from "../../assets/images/tiktok.svg";
import twittterIcon from "../../assets/images/twitter.svg";
import youtube_shortsIcon from "../../assets/images/youtube_shorts.svg";
import DomainSelect from "../DomainSelection/DomainSelect";
import AddSchedulerModal from "../../modals/NewSchedulerModal";
import { Scheduler as SchedulerType } from "../../utils/types";
import { EditIcon } from "../../assets/images/edit";
import { DeleteIcon } from "../../assets/images/delete";
import { COLORS } from "../../utils/colors";
import { checkPermissionOk } from "../../utils/utils";

const Scheduler = () => {
  const [filters, setFilters] = useState<any>(undefined);
  const [data, setData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entity, setEntity] = useState<string>("");
  const [showAddSchedulerModal, setShowAddSchedulerModal] =
    useState<boolean>(false);
  const [platforms, setPlatforms] = useState<string | undefined>(undefined);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<SchedulerType>({} as SchedulerType);
  const permissions = useSelector((state: AppState) => state.permissions); 
  const [api, contextHolder] = notification.useNotification();
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);
  const { user } = useAuth0();

  useEffect(() => {
    getData();
  }, []);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  const onFinish = async (scheduler: SchedulerType) => {
    setShowAddSchedulerModal(false);
    let response = await setScheduler(scheduler);
    if (response.status && response.status === "scheduler exist") {
      openNotificationWithIcon(
        "warning",
        "You've already created this scheduler"
      );
    } else {
      openNotificationWithIcon("success", "Scheduler set successfully");
      sendGtmEvent(`Set scheduler for Social Posts`, user);
      await getData();
    }
  };

  const onFinishEdit = async (scheduler: SchedulerType) => {
    setShowEditModal(false);
    await updateScheduler(scheduler);
    openNotificationWithIcon("success", "Scheduler set successfully");
    sendGtmEvent(`Edit scheduler for Social Posts`, user);
    await getData();
  };

  const platformOptions = [
    {
      key: 1,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Image src={instagramIcon} preview={false} />
          Instagram
        </div>
      ),
      value: "instagram",
    },
    {
      key: 2,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Image src={tiktokIcon} preview={false} />
          Tiktok
        </div>
      ),
      value: "tiktok",
    },
    {
      key: 3,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Image src={twittterIcon} preview={false} />
          Twitter
        </div>
      ),
      value: "twitter",
    },
    {
      key: 4,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Image src={twittterIcon} preview={false} />
          Twitter Legacy
        </div>
      ),
      value: "twitter_legacy",
    },
    {
      key: 5,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Image src={youtube_shortsIcon} preview={false} />
          YouTube Shorts
        </div>
      ),
      value: "youtube_shorts",
    },
  ];

  const getColumns = () => {
    return [
      {
        title: "Publisher Name",
        key: "pub_name",
        dataIndex: "pub_name",
      },
      {
        title: "Domain Name",
        key: "name",
        dataIndex: "name",
      },
      {
        title: "Platform",
        key: "platform",
        dataIndex: "platform",
      },
      {
        title: "Username",
        key: "username",
        dataIndex: "username",
      },
      {
        title: "Rate",
        key: "rate",
        dataIndex: "rate",
      },
      {
        title: "Widget Id",
        key: "widget_id",
        dataIndex: "widget_id",
      },
      {
        title: "Last updated date",
        key: "last_updated_date",
        dataIndex: "last_updated_date",
      },
      {
        title: "Operation",
        key: "operation",
        dataIndex: "operation",
        render: (_: any, record: any) => (
          <Row style={{ justifyContent: "center" }}>
            <Space>
              <Button
                permission="update:scheduler"
                type="default"
                onClick={() => {
                  setSelectedRecord(record);
                  setShowEditModal(true);
                }}
                icon={
                  <Tooltip
                    placement="topLeft"
                    title={"Edit"}
                    overlayStyle={{ maxWidth: "400px" }}
                  >
                    <span className="icon">
                      <EditIcon
                        color={
                          isDarkMode
                            ? COLORS.darkMode.main
                            : COLORS.lightMode.buttons
                        }
                      />
                    </span>
                  </Tooltip>
                }
              ></Button>
  
              <Popconfirm
                title="Delete"
                style={{ background: "red" }}
                description="Are you sure you want to delete the Scheduler?"
                placement="topLeft"
                onConfirm={async () => {
                   await deleteScheduler(record);
                   openNotificationWithIcon("success", "Scheduler deleted successfully");
                   getData();
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  permission="delete:scheduler"
                  type="default"
                  onClick={() => {}}
                  icon={
                    <Tooltip
                      placement="topLeft"
                      title={"Delete"}
                      overlayStyle={{ maxWidth: "400px" }}
                    >
                      <span className="icon">
                        <DeleteIcon
                          color={
                            isDarkMode
                              ? COLORS.darkMode.main
                              : COLORS.lightMode.buttons
                          }
                        />
                      </span>
                    </Tooltip>
                  }
                ></Button>
              </Popconfirm>
            </Space>
          </Row>
        ),
      }
    ];
  };

  const getData = async () => {
    setIsLoading(true);
    let data = await getAllSchedulers();
    setData(data);
    setIsLoading(false);
  };

  return (
    <Row className="unit-select">
      <Row justify="space-between" className="full-width">
        <Col className="title">
          <div style={{ color: isDarkMode ? "#FDB813" : "#4097FF" }}>
            Social Unit Scheduler
          </div>
        </Col>
        <Col className="buttons">
          <Button
            type="primary"
            title="Add New Scheduler"
            onClick={() => setShowAddSchedulerModal(true)}
          />
        </Col>
      </Row>
      <Row
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {permissions && checkPermissionOk("read:domains", permissions) && (
          <Col span={5}>
            <PublisherSelect
              allowClear
              mode="new"
              onSelect={(pub_id) => {
                if (pub_id) {
                  setFilters({ ...filters, pub_id });
                } else {
                  let obj = filters;
                  delete obj.pub_id;
                  setFilters({ ...obj });
                }
              }}
            />
          </Col>
        )}
        <Col span={5}>
          <DomainSelect
            allowClear
            onSelect={(value: any) => {
              setFilters({ ...filters, domain_id: value });
            }}
          />
        </Col>

        <Col span={4}>
          <Input
            allowClear
            style={{ height: "40px" }}
            placeholder="Type @username"
            value={entity}
            onChange={(e) => {
              let value = e.target.value;
              setFilters({ ...filters, username: value });
              if (value.includes("@")) {
                setEntity(value);
              } else {
                if (value !== "") {
                  setEntity("@" + value);
                } else setEntity("");
              }
            }}
          ></Input>
        </Col>

        <Col span={5}>
          <Select
            allowClear
            placeholder="Select platform"
            onChange={(value: string) => {
              if (value) {
                setPlatforms(value);
                setFilters({ ...filters, platform: value });
              } else {
                setPlatforms(undefined);
                let obj = filters;
                delete obj.platform;
                setFilters({ ...obj });
              }
            }}
            value={platforms}
            options={platformOptions}
          ></Select>
        </Col>

        <Col span={4}>
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Select rate"
            options={[
              { value: "daily", label: "Daily" },
              { value: "weekly", label: "Weekly" },
              { value: "monthly", label: "Monthly" },
            ].map((item: any) => {
              return { value: item.value, label: item.label };
            })}
            onChange={(value) => setFilters({ ...filters, rate: value })}
          />
        </Col>
      </Row>

      <Divider />

      {data && (
        <Row className="full-width">
          <PureCard>
            <Table
              rowKey={(data) => data.id}
              columns={getColumns()}
              dataSource={data.filter((record: any) => {
                for (const key in filters) {
                  if (key === "username") {
                    if (!record[key].includes(filters[key])) return false;
                  } else if (
                    filters[key] &&
                    filters[key] !== "" &&
                    filters[key] !== record[key]
                  )
                    return false;
                }

                return true;
              })}
              loading={isLoading}
            />
          </PureCard>
        </Row>
      )}

      {showAddSchedulerModal && (
        <AddSchedulerModal
          close={() => setShowAddSchedulerModal(false)}
          onFinish={onFinish}
          mode={"new"}
        />
      )}

      {showEditModal && (
        <AddSchedulerModal
          close={() => setShowEditModal(false)}
          onFinish={onFinishEdit}
          mode={"edit"}
          data={selectedRecord}
        />
        )}

      {contextHolder}
    </Row>
  );
};

export default Scheduler;
