import dayjs from "dayjs";
import { getDomainsByPubId } from "../api/services/Domains";
import { v4 as uuidv4 } from "uuid";
import { adTypes } from "./types";

export const getCardValue = (field: any, platformValue: string) => {
  switch (field.Label) {
    case "CPM": {
      let value = 0;
      if (platformValue === "All") {
        value = field.Total;
      }
      if (platformValue === "Desktop") {
        value = field.Desktop;
      }
      if (platformValue === "Mobile") {
        value = field.Mobile;
      }
      return value;
    }
    case "Monetized Page Views":
    case "Monetized Widget Loads": {
      let value = 0;
      if (platformValue === "All") {
        value = field.Total.toString();
        return value;
      }
      if (platformValue === "Desktop") {
        value = field.Desktop.toString();
        return value;
      }
      if (platformValue === "Mobile") {
        value = field.Mobile.toString();
        return value;
      }
      return value;
    }
    case "Widget Load": {
      let value = 0;
      if (platformValue === "All") {
        value = parseFloat(field.Total || 0);
      }
      if (platformValue === "Desktop") {
        value = parseFloat(field.Desktop || 0);
      }
      if (platformValue === "Mobile") {
        value = parseFloat(field.Mobile || 0);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    case "Revenue": {
      let value = 0;
      if (platformValue === "All") {
        value = field.Total;
      }
      if (platformValue === "Desktop") {
        value = field.Desktop;
      }
      if (platformValue === "Mobile") {
        value = field.Mobile;
      }
      return value;
    }

    case "Viewability": {
      let value = 0;
      if (platformValue === "All") {
        value = field.Total;
      }
      if (platformValue === "Desktop") {
        value = field.Desktop;
      }
      if (platformValue === "Mobile") {
        value = field.Mobile;
      }
      return value;
    }

    default:
      return field.Total;
  }
};

export const getDataSource = (dashboardData: any) => {
  let tmp: any[] = [];
  for (let item of dashboardData.IntervalData) {
    let obj: any = {};
    obj["date"] = dayjs(item.date).format("MM/DD/YYYY");
    for (let intervalItem of item.data) {
      obj[intervalItem.Label] = intervalItem.Total;
    }
    tmp.push(obj);
  }
  return tmp;
};

export const getReportData = (dashboardData: any) => {
  let tmp: any[] = [];
  for (let item of dashboardData.IntervalDataByDomaines) {
    let obj: any = {};
    obj["date"] = dayjs(item.date).format("MM/DD/YYYY");
    obj["domain"] = item.domainName;
    for (let intervalItem of item.data) {
      obj[intervalItem.Label] = intervalItem.Total;
    }
    tmp.push(obj);
  }
  return tmp;
};

export const getallDomainesUrl = async (pub_id: number) => {
  let response = await getDomainsByPubId(pub_id);
  let tmpOptions = [];
  for (let i = 0; i < response?.length; i++) {
    tmpOptions.push({
      label: response[i].name,
      value: response[i].domain_id,
    });
  }
  return tmpOptions;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const validURL = (str: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const createDefaultWidget = (pub_id: number) => ({
  id: uuidv4(),
  ref_domain_id: 0,
  ref_pid: pub_id,
  widget_id: 0,
  is_in_content: 0,
  is_mobile: 0,
  is_tablet: 0,
  item_type: "",
  layout_type: "",
  widget_title: "",
  widget_frame_width: "",
  widget_title_font: "",
  InContentTitleColor: "",
  InContentTitleBackgroundColor: "",
  InContentUnitBackgroundColor: "",
  InContentUnitFrameColor: "",
});

export const getUuidv4 = () => {
  return uuidv4();
};
export const validatorInput = async (
  value: any,
  fieldName: string,
  maxLength: number
) => {
  let val = value.trim();
  let len = val.length;
  if (len > maxLength) {
    return Promise.reject(`${fieldName} is too long`);
  } else if (len < 1 && value) {
    return Promise.reject(`${fieldName} cannot be empty`);
  } else {
    return Promise.resolve();
  }
};

export const defaultTimer = 100000;

export const validateField = (_: any, value: any) => {
  if (value.trim() === "") {
    return Promise.reject("Field is required");
  }
  return Promise.resolve();
};

export const validateNegativeField = (_: any, value: number) => {
  if (value < 1) {
    return Promise.reject();
  }
  return Promise.resolve();
};

export const adTypeOptions = [
  {
    value: "firstImpression",
    label: adTypes.firstImpression,
  },
  { value: "inContent", label: adTypes.inContent },
  { value: "inContent1", label: adTypes.inContent1 },
  { value: "inContent2", label: adTypes.inContent2 },
  { value: "inContent3", label: adTypes.inContent3 },
  { value: "inContent4", label: adTypes.inContent4 },
  { value: "inContent5", label: adTypes.inContent5 },
  { value: "inContent6", label: adTypes.inContent6 },
  { value: "inContent7", label: adTypes.inContent7 },
  { value: "inContent8", label: adTypes.inContent8 },
  { value: "inContent9", label: adTypes.inContent9 },
  { value: "inContent10", label: adTypes.inContent10 },
  { value: "outOfFrame", label: adTypes.outOfFrame },
];

export const unitSizeOptions = [
  "300x250",
  "728x90",
  "300x600",
  "336x280",
  "728x340",
  "300x50",
  "320x50",
];
export const providersOptions = [
  {
    providerName: "33across",
    fields: ["siteId", "productId"],
  },
  {
    providerName: "eplanning",
    fields: ["ci"],
  },
  {
    providerName: "smartadserver",
    fields: ["networkId", "siteId", "pageId", "formatId"],
  },
  {
    providerName: "rubicon",
    fields: ["accountId", "siteId", "zoneId"],
  },
  {
    providerName: "openx",
    fields: ["delDomain", "unitId"],
  },
  {
    providerName: "pubmatic",
    fields: ["publisherId"],
  },
  {
    providerName: "rise",
    fields: ["org"],
  },
  {
    providerName: "sovrn",
    fields: ["tagid"],
  },
  {
    providerName: "appnexus",
    fields: ["placementId"],
  },
  {
    providerName: "nextMillennium",
    fields: ["placement_id"],
  },
  {
    providerName: "minutemedia",
    fields: ["org"],
  },
  {
    providerName: "onetag",
    fields: ["pubId"],
  },  
];

export const widgetContentType = [
  { value: "legacy", label: "Legacy" },
  { value: "ai", label: "Ai" },
];

export const isActiveOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

export const teleportisActiveOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

export const widget_frame_width_options = [
  { value: "0", label: "0" },
  { value: "1px", label: "1px" },
  { value: "2px", label: "2px" },
  { value: "3px" , label: "3px" },
  { value: "4px", label: "4px" },
  { value: "5px", label: "5px" },
];

export const social_follow_placement_options = [
  { value: "after_1_post", label: "After 1 post" },
  { value: "after_every_1_post", label: "After every 1 post" },
  { value: "after_2_post", label: "After 2 post" },
  { value: "after_every_2_post", label: "After every 2 post" },
  { value: "after_3_post", label: "After 3 post" },
  { value: "after_every_3_post", label: "After every 3 post" },
  { value: "after_4_post", label: "After 4 post" },
  { value: "after_every_4_post", label: "After every 4 post" },
  { value: "after_5_post", label: "After 5 post" },
  { value: "after_every_5_post", label: "After every 5 post" },
];

export const emailValidator = (rule: any, values: any, callback: any) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const invalidInputs = values.filter(
    (value: any) => !value.trim().match(emailRegex)
  );
  if (invalidInputs.length === 0) {
    callback();
  } else if (invalidInputs.length === 1) {
    callback(invalidInputs.join("") + " is not a valid email");
  } else {
    callback(
      invalidInputs.slice(0, -1).join(", ") +
        " and " +
        invalidInputs.slice(-1) +
        " are not valid emails"
    );
  }
};

export const objectsEqual = (o1: any, o2: any) => {
  return (
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p])
  );
};
export const FormatDateConvert = (dateObject: Date) => {
  return `${(dateObject.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${dateObject
    .getDate()
    .toString()
    .padStart(2, "0")}/${dateObject.getFullYear()} ${dateObject
    .getHours()
    .toString()
    .padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}`;
};

export const checkPermissionOk = (
  permission: string,
  permissions: string[]
) => {
  let permissionScope = permission.split(":")[0];
  
    return (
      permissions.includes(permission) ||
      permissions.includes(`${permissionScope}:all`)
    );
  
};
