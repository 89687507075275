import Select from "../../components/Select/Select";
import { useEffect, useState } from "react";
import { Form, Input, Row, Spin, Divider, InputNumber } from "antd";
import type { Color } from "antd/es/color-picker";
import ColorPickerWidget from "../../pages/Publishers/ColorPickerWidget";

import {
  createDefaultWidget,
  isActiveOptions,
  widget_frame_width_options,
  social_follow_placement_options,
  teleportisActiveOptions,
  widgetContentType,
} from "../../utils/utils";

const Widget = (props: any) => {
  const {
    pub_id,
    data,
    onLoad,
    onUpdateWidget,
    mode,
    allWidgets,
    allLayoutTypes,
    allItemTypes,
  } = props;

  const [InContentTitleColor, setInContentTitleColor] = useState<
    Color | string
  >("rgb(0, 0, 0)");

  const [InContentTitleBackgroundColor, setInContentTitleBackgroundColor] = useState<
    Color | string
  >("rgb(255, 255, 255)");

  const [InContentUnitBackgroundColor, setInContentUnitBackgroundColor] = useState<
    Color | string
  >("rgb(255, 255, 255)");

  const [InContentUnitFrameColor, setInContentUnitFrameColor] = useState<
    Color | string
  >("rgb(255, 255, 255)");

  const PLATFORMS = ["Desktop", "Mobile", "Tablet"];
  const [loading, setLoading] = useState<boolean>(false);

  const [widget, setWidget] = useState<any>(
    data ? data : createDefaultWidget(pub_id)
  );

  const WIDGET_PROPERTIES_MAP = {
    Desktop: "is_in_content",
    Mobile: "is_mobile",
    Tablet: "is_tablet",
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getData = async () => {
    if (mode === "edit") {
      setWidget({
        ...widget,
        item_type: data.item_type !== "" ? data.item_type : "",
        layout_type: data.layout_type !== "" ? data.layout_type : "",
        widget_title: data.widget_title !== "" ? data.widget_title : "",
        widget_frame_width: data.widget_frame_width !== "" ? data.widget_frame_width : "",
        widget_title_font: data.widget_title_font !== "" ? data.widget_title_font : "",
        InContentTitleColor: data.InContentTitleColor !== "" ? data.InContentTitleColor : "",      
        InContentTitleBackgroundColor: data.InContentTitleBackgroundColor !== "" ? data.InContentTitleBackgroundColor : "",
        InContentUnitBackgroundColor: data.InContentUnitBackgroundColor !== "" ? data.InContentUnitBackgroundColor : "",
        InContentUnitFrameColor: data.InContentUnitFrameColor !== "" ? data.InContentUnitFrameColor : "",
        social_follow_placement: data.social_follow_placement !== "" ? data.social_follow_placement : "",
        social_follow_link: data.social_follow_link !== "" ? data.social_follow_link : "",
        social_follow_abtest: data.social_follow_abtest !== "" ? data.social_follow_abtest : "",
      });
      if(data.InContentTitleColor)
      {
        setInContentTitleColor(data.InContentTitleColor);
      }
      if(data.InContentTitleBackgroundColor)
      {
        setInContentTitleBackgroundColor(data.InContentTitleBackgroundColor);
      }
      if(data.InContentUnitBackgroundColor)
      {
        setInContentUnitBackgroundColor(data.InContentUnitBackgroundColor);
      }
      if(data.InContentUnitFrameColor)
      {
        setInContentUnitFrameColor(data.InContentUnitFrameColor);
      }
    } else {
      setWidget({
        ...widget,
        item_type: "",
        layout_type: "",
        widget_title: "",
        widget_frame_width: "",
        widget_title_font: "",
        InContentTitleColor: "",
        InContentTitleBackgroundColor: "",
        InContentUnitBackgroundColor: "",
        InContentUnitFrameColor: "",
        social_follow_placement: "",
        social_follow_link: "",
        social_follow_abtest: ""
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (mode === "new" || typeof data.id === "string") {
        setLoading(true);
      }
      await getData();
      onLoad();
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (onUpdateWidget) {
      onUpdateWidget(widget);
    }
  }, [widget]);

  return (
    <div>
      <Divider/>
      <Spin spinning={loading}>
        <Row justify="space-between">
          <div style={{ width: "8%" }}>
            <Form.Item label={"WID"} name={`wid_${widget.id}`}>
              <Select
                showSearch
                placeholder="Select WID"
                optionFilterProp="children"
                filterOption={filterOption}
                options={allWidgets.map((wid: any) => {
                  return { value: wid, label: wid.toString() };
                })}
                {...(widget && {
                  defaultValue: widget.widget_id,
                })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    widget_id: value,
                  });
                }}
              />
            </Form.Item>
          </div>

          <div style={{ width: "22%" }}>
            <Form.Item
              label={"Choose Platforms"}
              name={`platforms_${widget.id}`}
              initialValue={[
                ...(widget.is_in_content ? ["Desktop"] : []),
                ...(widget.is_mobile ? ["Mobile"] : []),
                ...(widget.is_tablet ? ["Tablet"] : []),
              ]}
              rules={[
                {
                  required: true,
                  message: "Please Choose Platform",
                },
              ]}
            >
              <Select
                showSearch
                mode="multiple"
                placeholder="Select Platform"
                className="platform"
                options={PLATFORMS.map((plt: any) => {
                  return { value: plt, label: plt };
                })}
                defaultValue={[
                  ...(widget.is_in_content ? ["Desktop"] : []),
                  ...(widget.is_mobile ? ["Mobile"] : []),
                  ...(widget.is_tablet ? ["Tablet"] : []),
                ]}
                onSelect={(selectedPlatform: string) => {
                  const selected =
                    WIDGET_PROPERTIES_MAP[
                      selectedPlatform as keyof typeof WIDGET_PROPERTIES_MAP
                    ];

                  setWidget({
                    ...widget,
                    [selected]: 1,
                  });
                }}
                onDeselect={(deselectedPlatform: string) => {
                  const deselected =
                    WIDGET_PROPERTIES_MAP[
                      deselectedPlatform as keyof typeof WIDGET_PROPERTIES_MAP
                    ];

                  setWidget({
                    ...widget,
                    [deselected]: 0,
                  });
                }}
              />
            </Form.Item>
          </div>
          <div style={{ width: "17%" }}>
            <Form.Item
              name={`layoutType_${widget.id}`}
              label={"Choose Layout Type"}
              key={`layout_type_${widget.id}`}
              {...(widget.layout_type && { initialValue: widget.layout_type })}
              rules={[
                {
                  required: true,
                  message: "Please Choose Layout Type",
                },
              ]}
            >
              <Select
                allowClear
                placeholder="Select Layout"
                options={allLayoutTypes.map((layout: any) => {
                  return { value: layout, label: layout };
                })}
                {...(widget.layout_type && {
                  defaultValue: widget.layout_type,
                })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    layout_type: value,
                  });
                }}
              />
            </Form.Item>
          </div>

          <div style={{ width: "17%" }}>
            <Form.Item
              name={`itemType_${widget.id}`}
              label="Choose Item Type"
              key={`itemType_${widget.id}`}
              rules={[
                {
                  required: true,
                  message: "Please Choose Item Type",
                },
              ]}
              {...(widget.item_type && { initialValue: widget.item_type })}
            >
              <Select
                allowClear
                placeholder="Select Item Type"
                options={allItemTypes.map((item: any) => {
                  return { label: item, value: item };
                })}
                {...(widget.item_type && { defaultValue: widget.item_type })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    item_type: value,
                  });
                }}
              />
            </Form.Item>
          </div>

          <div style={{ width: "15%" }}>
            <Form.Item
              name={`contentType_${widget.id}`}
              label="Choose Content Type"
              key={`contentType_${widget.id}`}
              rules={[
                {
                  required: true,
                  message: "Please Choose Content Type",
                },
              ]}
              {...(widget.contentType && { initialValue: widget.contentType })}
            >
              <Select
                allowClear
                placeholder="Select Content Type"
                options={widgetContentType.map((item: any) => {
                  return { label: item.label, value: item.value };
                })}
                {...(widget.contentType && {
                  defaultValue: widget.contentType,
                })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    contentType: value,
                  });
                }}
              />
            </Form.Item>
          </div>

          <div style={{ width: "16%" }}>
            <Form.Item
              name={`active${widget.id}`}
              label="Choose Is Active"
              key={`active_${widget.id}`}
              rules={[
                {
                  required: true,
                  message: "Please Choose Is Active",
                },
              ]}
              {...(widget.active != null && { initialValue: widget.active })}
            >
              <Select
                allowClear
                placeholder="Select Is Active"
                options={isActiveOptions.map((item: any) => {
                  return { label: item.label, value: item.value };
                })}
                {...(widget.active != null && {
                  defaultValue: widget.active,
                })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    active: value,
                  });
                }}
              />
            </Form.Item>
          </div>
        </Row>
        <Row justify="space-between">
        <Form.Item
          label="Widget Title"
          name={`widget_title_${widget.id}`}
          key={`widget_title_${widget.id}`}
          className="form-item"
          {...(widget.widget_title && { initialValue: widget.widget_title })}
        >
          <Input
            placeholder="Please Choose Widget Title"
            size="large"
            onChange={(value) => {
              setWidget({
                ...widget,
                widget_title: value.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Widget Title Font"
          name={`widget_title_font_${widget.id}`}
          key={`widget_title_font_${widget.id}`}
          className="form-item"
          {...(widget.widget_title_font && { initialValue: widget.widget_title_font })}
        >
          <Input
            placeholder="Please Choose Widget Title Font"
            size="large"
            onChange={(value) => {
              setWidget({
                ...widget,
                widget_title_font: value.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
              className="form-item"
              name={`widget_frame_width_${widget.id}`}
              label="Unit Frame Width"
              key={`widget_frame_width_${widget.id}`}
              {...(widget.widget_frame_width && { initialValue: widget.widget_frame_width })}
            >
              <Select
                allowClear
                placeholder="Select Unit Frame Width"
                options={widget_frame_width_options.map((item: any) => {
                  return { label: item.label, value: item.value };
                })}
                {...(widget.widget_frame_width != null && {
                  defaultValue: widget.widget_frame_width,
                })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    widget_frame_width: value,
                  });
                }}
              />
            </Form.Item>
        </Row>
        <Row justify="start">
        <ColorPickerWidget
          ColorLabel={"Title Color"}
          ColorName={"InContentTitleColor"}
          keyColor={`InContentTitleColor_${widget.id}`}
          colorRgb={InContentTitleColor}
          setColorRgb={setInContentTitleColor}
          widget={widget}
          setWidget={setWidget}
        />
        <ColorPickerWidget
          ColorLabel={"Title Background Color"}
          ColorName={"InContentTitleBackgroundColor"}
          keyColor={`InContentTitleBackgroundColor_${widget.id}`}
          colorRgb={InContentTitleBackgroundColor}
          setColorRgb={setInContentTitleBackgroundColor}
          widget={widget}
          setWidget={setWidget}
        />
        <ColorPickerWidget
          ColorLabel={"Unit Background Color"}
          ColorName={"InContentUnitBackgroundColor"}
          keyColor={`InContentUnitBackgroundColor_${widget.id}`}
          colorRgb={InContentUnitBackgroundColor}
          setColorRgb={setInContentUnitBackgroundColor}
          widget={widget}
          setWidget={setWidget}
        />
        <ColorPickerWidget
          ColorLabel={"Unit Frame Color"}
          ColorName={"InContentUnitFrameColor"}
          keyColor={`InContentUnitFrameColor_${widget.id}`}
          colorRgb={InContentUnitFrameColor}
          setColorRgb={setInContentUnitFrameColor}
          widget={widget}
          setWidget={setWidget}
        />
        </Row>
        <Row justify="space-evenly">
        <Form.Item
          label="Social Follow Link"
          name={`social_follow_link_${widget.id}`}
          key={`social_follow_link_${widget.id}`}
          className="form-item"
          {...(widget.social_follow_link && { initialValue: widget.social_follow_link })}
        >
          <Input
            placeholder="Please Choose Social Follow Link"
            size="large"
            onChange={(value) => {
              setWidget({
                ...widget,
                social_follow_link: value.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item
              className="form-item"
              name={`social_follow_placement_${widget.id}`}
              label="Social Follow Placement"
              key={`social_follow_placement_${widget.id}`}
              {...(widget.social_follow_placement && { initialValue: widget.social_follow_placement })}
            >
              <Select
                allowClear
                placeholder="Social Follow Placement"
                options={social_follow_placement_options.map((item: any) => {
                  return { label: item.label, value: item.value };
                })}
                {...(widget.social_follow_placement != null && {
                  defaultValue: widget.social_follow_placement,
                })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    social_follow_placement: value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Social Follow A/B Test"
              name={`social_follow_abtest_${widget.id}`}
              key={`social_follow_abtest_${widget.id}`}
              {...(widget.social_follow_abtest && { initialValue: widget.social_follow_abtest })}
            >
              <InputNumber<number>
                placeholder="Please Type Social Follow A/B Test"
                min={0}
                max={100}
                size="large"
                formatter={(value) => {
                  if (value == null || value == 0) {
                    return '0%';
                  }
                  return `${value}%`;
                }}
                parser={(value) => parseInt(value?.replace('%', '') || '0', 10)}
                onChange={(value: any) => {
                  setWidget({
                    ...widget,
                    social_follow_abtest: value,
                  });
                }}
              />
            </Form.Item>            
            <Form.Item
              className="form-item"
              name={`teleport_${widget.id}`}
              label="Replace Embed"
              key={`teleport_${widget.id}`}
              {...(widget.teleport && { initialValue: widget.teleport })}
            >
              <Select
                allowClear
                placeholder="Replace Embed"
                options={teleportisActiveOptions.map((item: any) => {
                  return { label: item.label, value: item.value };
                })}
                {...(widget.teleport != null && {
                  defaultValue: widget.teleport,
                })}
                onSelect={(value) => {
                  setWidget({
                    ...widget,
                    teleport: value,
                  });
                }}
              />
            </Form.Item>
        </Row>
      </Spin>
    </div>
  );
};
export default Widget;
